<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <strong>User</strong>
          </CCardHeader>
          <CRow>
            <CCol md="10">
              <CCardBody>
                <CForm>
                  <CInput
                    label="First Name *"
                    v-model="users.firstName"
                    horizontal
                    :disabled="mode.viewMode"
                    :invalid-feedback="'Please Enter the First Name'"
                    @blur="$v.users.firstName.$touch()"
                    :is-valid="
                      $v.users.firstName.$dirty
                        ? !$v.users.firstName.$error
                        : null
                    "
                  />
                  <CInput
                    label="Last Name *"
                    v-model="users.lastName"
                    horizontal
                    :disabled="mode.viewMode"
                    :invalid-feedback="'Please Enter the Last Name'"
                    @blur="$v.users.lastName.$touch()"
                    :is-valid="
                      $v.users.lastName.$dirty
                        ? !$v.users.lastName.$error
                        : null
                    "
                  />
                  <CInput
                    label="IC Number *"
                    v-model="users.ic_n"
                    horizontal
                    :disabled="mode.viewMode"
                    :invalid-feedback="'Please Enter the IC Number'"
                    @blur="$v.users.ic_n.$touch()"
                    :is-valid="
                      $v.users.ic_n.$dirty ? !$v.users.ic_n.$error : null
                    "
                  />
                  <CInput
                    label="Email *"
                    v-model="users.email"
                    horizontal
                    :disabled="mode.viewMode"
                    :invalid-feedback="'Please Enter the Email'"
                    @blur="$v.users.email.$touch()"
                    :is-valid="
                      $v.users.email.$dirty ? !$v.users.email.$error : null
                    "
                  />
                  <CInput
                    label="Mobile "
                    v-model="users.mobile"
                    horizontal
                    type="number"
                    :disabled="mode.viewMode"
                  />
                  <!-- <CInput
                label="Password *"
                v-model="users.password"
                horizontal
               
                :disabled="mode.viewMode && mode.editMode"
                 :invalid-feedback="'Please Enter the Email'"
                   
                    @blur="$v.users.password.$touch()"
                     :is-valid="$v.users.password.$dirty? !$v.users.password.$error : null "
             
             />  -->
                  <div class="form-group row">
                    <label
                      for="example-text-input text-right"
                      class="col-sm-3 col-form-label"
                      >Role
                    </label>
                    <div class="col-sm-9">
                      <select
                        class="form-control"
                        v-model="users.authorityType"
                        :disabled="mode.viewMode"
                      >
                        <option disabled value="">--Select One Option--</option>
                        <!-- <option value="ROLE_USER">ROLE_USER</option> -->
                        <option value="1">SUPERVISOR</option>
                        <option value="2">TEAM IC</option>
                        <option value="3">TECHNICIAN</option>
                      </select>
                    </div>
                  </div>

                  <CInput
                    label="Designation "
                    v-model="users.designation"
                    horizontal
                    :disabled="mode.viewMode"
                    :invalid-feedback="'Please Enter the Designation'"
                    @blur="$v.users.designation.$touch()"
                  />
                  <!-- <CSelect
                label="Role *"
                size="bg"
                horizontal
                :options="users.authority"
                :value.sync="selectedOption"
                placeholder="Please select"
              /> -->
                  <!-- :value.sync="selectedOption"
                :options="selectOptions" -->
                  <!-- 
                options: ['Option 1', 'Option 2', 'Option 3'],
      selectOptions: [
        'Option 1', 'Option 2', 'Option 3',
        { 
          value: 'some value', 
          label: 'Selected option'
        }
      ],
      selectedOption: 'some value', -->
                  <!-- <CSelect
                label="Large select"
                size="lg"
                horizontal
                :value.sync="selectedOption"
                :options="selectOptions"
                placeholder="Please select"
                
              /> -->
                </CForm>
              </CCardBody>
            </CCol>
          </CRow>

          <CCardFooter align="right">
            <CButton
              v-if="mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="primary"
              @click="editMode()"
            >
              <CIcon name="cil-pencil" /> Edit
            </CButton>

            <CButton
              v-if="!mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="success"
              @click="Saveuser()"
            >
              <CIcon name="cil-check-circle" /> Save
            </CButton>

            <CButton
              class="mr-2"
              type="Cancel"
              size="sm"
              color="danger"
              @click="cancel()"
            >
              <CIcon name="cil-ban" /> Cancel
            </CButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";
import datetime from "vuejs-datetimepicker";
import {
  required,
  email,
  minLength,
  maxLength,
  url,
  requiredIf,
} from "vuelidate/lib/validators";
export default {
  name: "Department User",
  data() {
    return {
      mode: {
        newMode: false,
        editMode: false,
        viewMode: false,
      },

      uid: "",
      departmentId: "",
      users: {
        uid: "0",
        departmentId: "",

        firstName: "",
        lastName: "",
        email: "",
        ic_n: "",
        authority: "",
        authorityType: "",
        designation: "",
        //  password:"",
        mobile: "",
        // opetions:['Admin', 'Officer', 'Supervisor','Technicians'],
      },

      horizontal: { label: "col-3", input: "col-9" },
    };
  },

  validations: {
    users: {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      email: {
        required,
        email,
      },
      ic_n: {
        required,
      },
      //  password: {
      //      required,

      // },
      //  email: {
      //      required,

      // }
      // authority: {
      //      required,

      // },
    },
  },
  created() {
    if (this.$route.params.mode == "new") {
      this.mode.newMode = true;
      this.mode.viewMode = false;
      this.mode.editMode = false;
      this.departmentId = this.$route.params.departmentId;
    }
    if (this.$route.params.mode == "view") {
      this.mode.viewMode = true;
      this.departmentId = this.$route.params.departmentId;
      //this.uid = this.$route.params.uid;
      this.userId = this.$route.params.uid;
      this.getUser();
    }
    if (this.$route.params.mode == "edit") {
      this.mode.editMode = true;
      this.departmentId = this.$route.params.departmentId;
      this.userId = this.$route.params.uid;
      this.getUser();
    }
  },
  components: { datetime },
  methods: {
    editMode() {
      this.mode.editMode = true;
      this.mode.viewMode = false;
    },
    cancel() {
      window.history.back();
    },

    Saveuser() {
      console.log("Saveuser(): this.departmentId", this.departmentId);
      // var urlForSaveUser =
      //   process.env.VUE_APP_API_HOST +"/emapp/web/user/save/"+
      //   this.departmentId;
      // console.log("Saveuser(): url", urlForSaveUser);
      console.log("this.users", JSON.stringify(this.users));
      this.$v.users.$touch();
      if (this.$v.users.$invalid) {
        console.log("form is invalid", JSON.stringify(this.$v.users));
      } else {
        // fetch(urlForSaveUser, {
        //   method: "POST",
        //   headers: {
        //     "Content-type": "application/json",
        //   },
        //   body: JSON.stringify(this.users),
        // })

        this.apiPostSecure(
          process.env.VUE_APP_API_HOST +
            "/emapp/web/secure/user/save/" +
            this.departmentId,
          this.users
        ).then((users) => {
          console.log(JSON.stringify(users));
          // this.$router.push({ name: "Groups" });
          window.history.back();
        });
      }
    },

    validator(val) {
      return val ? val.length >= 4 : false;
    },

    getUser() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/get/user/" +
          this.userId
      )
        .then((response) => response.json())
        .then((data) => (this.users = data));
    },
  },
  mounted() {
    //this. getRoles();
  },
};
</script>
